import { call, put, takeEvery, all, select } from "redux-saga/effects";

import { getReguest } from "./api";

import {
  getInitData,
  getInitDataSuccess,
  getInitDataFailure,
} from "./actions";
import { selectConfig } from "../config/selector";
import { getShopsByFilterSaga } from "../shopsByFilter/saga";

function iOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

function* getInitDataSaga(action) {
  try {
    const config = yield select(selectConfig);
    const payload = action.payload;
    const [
      categories,
      topSales,
      topAlpha,
      allSales,
      allSalesAlpha,
    ] = yield all([
      call(getReguest, {
        route:
          "/api/categories?" +
          "&populate[Shops][fields][0]=id&" +
          "populate[Icon][fields][0]=url" +
          "&pagination[pageSize]=100" +
          "&sort=Sort",
          ...config,
      }),
      call(getReguest, {
        route: "/api/sales?sort=Sort&populate[Sale_card][populate]=*",
        ...config,
      }),
      call(getReguest, {
        route:
          "/api/sale-alphas?filters[Top]=1&populate[Alpha_card][populate]=*",
          ...config,
      }),
      call(getReguest, { route: "/api/sales", ...config }),
      call(getReguest, { route: "/api/sale-alphas", ...config }),
    ]);
    const isiOS = iOS();
    let topAlphaData = topAlpha;
    if (isiOS) {
      topAlphaData = null;
      if (allSalesAlpha) {
        allSalesAlpha.data = [];
      }
    }

    let categoriesWithItemsIds = [];

    let categoriesById = {};

    let categoriesSortById = [];
    
    function sortBySortParameter(array) {
      return array.sort((a, b) => a.attributes.Sort - b.attributes.Sort);
    }

    if (categories && categories.data && categories.data.length > 0) {
      sortBySortParameter(categories.data).map((item) => {
        categoriesById = {
          ...categoriesById,
          [item.id]: item.attributes,
        };
      });

      console.log(sortBySortParameter(categories.data));

      sortBySortParameter(categories.data).map((item) => {
        categoriesSortById.push(item.id);
      });

    }

    let cities = {
      data: [],
    };

    let storeTypes = {
      data: [
        {
          id: 1,
          attributes: {
            Title: "Интернет-магазины",
          },
        },
        {
          id: 2,
          attributes: {
            Title: "Розничные магазины",
          },
        },
      ],
    };

    if (payload && payload.main) {
      yield call(getShopsByFilterSaga, {
        ...payload,
        categories: categories.data,
      });
    }

    yield put(
      getInitDataSuccess({
        categories,
        categoriesWithItemsIds,
        storeTypes,
        cities,
        topSales,
        topAlpha: topAlphaData,
        allSales,
        categoriesById,
        allSalesAlpha,
        categoriesSortById,
        config
      })
    );

  } catch (error) {
    console.log(error);

    yield put(getInitDataFailure(error));
  }
}

export function* getInitDataWatcher() {
  yield takeEvery(getInitData.toString(), getInitDataSaga);
}
