import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";

import PostSale from "../../../../components/postSale";
import PostShop from "../../../../components/postShop";
import { getUrlImage } from "../../../../constants/config";
import cn from "classnames"
import './style.css'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Navigation } from 'swiper/modules';

const Results = (props) => {

    const { id } = props;

    const shops = useSelector(state => state.category.shops);
    const sales = useSelector(state => state.category.sales);
    const topAlpha = useSelector(state => state.initData.topAlpha);
    const categoriesById = useSelector(state => state.initData.categoriesById);

    const allSalesCount = useSelector(state => state.initData.allSalesCount);
    
    const [salesSwiper, setSalesSwiper] = useState(null);
    
    const slider = useRef(null);


    return (
        <div className="results _category">
            {(sales && sales.length > 0 || topAlpha && topAlpha.length > 0) && (
                <div className="results__category _sales">
                    <div className='category _default'>
                        <div className='category__header'>
                            <div className='category__title'>Акции</div>
                            {allSalesCount && sales && sales.length > 0 && <Link to={`/sales/${id}`} className='category__link carousel-control'>Все {sales && sales.length}</Link>}
                        </div>
                        <div ref={slider} className={`category__slider ${(topAlpha && sales ) && topAlpha.length + sales.length > 3 && '_shadow-before' || ''}`}>
                            <Swiper
                                slidesPerView={'auto'}
                                spaceBetween={24}
                                navigation={true} 
                                modules={[Navigation]}
                                onSwiper={(swiper) => {
                                    // if(swiper.isEnd){
                                    //     if(slider && slider.current){
                                    //         slider.current.classList.remove('_shadow-before');
                                    //     }
                                    // }
                                    setSalesSwiper(swiper);
                                }}
                                onSlideChange={(swiper) => {
                                    
                                    if(swiper.activeIndex > 0){
                                        slider.current.classList.add('_shadow-after');
                                    } else {
                                        slider.current.classList.remove('_shadow-after');
                                    }
                                    if(swiper.isEnd){
                                        slider.current.classList.remove('_shadow-before');
                                    } else {
                                        slider.current.classList.add('_shadow-before');
                                    }
                                    
                                    // if(){

                                    // }
                                }}
                            >
                                {topAlpha && topAlpha.length > 0 && topAlpha.map((item, index) => {

                                const { attributes } = item;

                                const { Alpha_card } = attributes;

                                const { Link, Title_on_card, Preview, Description_on_card } = Alpha_card;

                                return (
                                    <SwiperSlide className='category__list-item' key={item.id}>
                                        <PostSale 
                                            type={'top'} 
                                            link={Link}
                                            preview={getUrlImage(Preview)} 
                                            title={Title_on_card}
                                            description={Description_on_card}
                                        />
                                    </SwiperSlide>
                                )
                                })}
                                {sales && sales.length > 0 && sales.map((item, index) => {

                                    const { id, attributes } = item;

                                    const { Sale_card, YandexTarget } = attributes;

                                    if(!Sale_card){
                                        return;
                                    }

                                    const { Percent, Preview, Logo, Percent_symbol, Percent_Symbol_Before } = Sale_card;

                                    if(!Preview || Preview && !Preview.data){
                                        return;
                                    }

                                    return (
                                        <SwiperSlide className='category__list-item' key={item.id}>
                                            <PostSale  
                                                id={id}
                                                preview={getUrlImage(Preview)} 
                                                logo={getUrlImage(Logo)}
                                                // price={Percent && ( Percent_Symbol_Before && Percent_Symbol_Before + ' ' && '-' ) + Percent + ( Percent_symbol && Percent_symbol || '%') || null}
                                                target={YandexTarget}
                                                attributes={attributes}
                                            />
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                            {(topAlpha && sales ) && topAlpha.length + sales.length > 3 && (
                                <>
                                    <button className='category__slider-prev' onClick={() => salesSwiper.slidePrev()}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14 17.3594L9.6652 13.2432C9.15327 12.7571 9.15327 11.9616 9.6652 11.4755L14 7.35938" stroke="#A7ABAC" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </button>
                                    <button className='category__slider-next' onClick={() => salesSwiper.slideNext()}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 17.3594L14.3348 13.2432C14.8467 12.7571 14.8467 11.9616 14.3348 11.4755L10 7.35938" stroke="#A7ABAC" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </button>
                                </>
                            )}
                        </div>
                        <div className='category__list'>
                            {topAlpha && topAlpha.length > 0 && topAlpha.map((item, index) => {

                            const { attributes } = item;

                            const { Alpha_card } = attributes;

                            const { Link, Title_on_card, Preview, Description_on_card } = Alpha_card;

                            return (
                                <div className='category__list-item' key={item.id}>
                                    <PostSale 
                                        type={'top'} 
                                        link={Link}
                                        preview={getUrlImage(Preview)} 
                                        title={Title_on_card}
                                        description={Description_on_card}
                                    />
                                </div>
                            )
                            })}
                            {sales && sales.length > 0 && sales.map((item, index) => {

                                const { id, attributes } = item;

                                const { Sale_card, YandexTarget } = attributes;

                                if(!Sale_card){
                                    return;
                                }

                                const { Percent, Preview, Logo, Percent_symbol, Percent_Symbol_Before } = Sale_card;

                                if(!Preview || Preview && !Preview.data){
                                    return;
                                }

                                return (
                                    <div className='category__list-item' key={item.id}>
                                        <PostSale  
                                            id={id}
                                            preview={getUrlImage(Preview)} 
                                            logo={getUrlImage(Logo)}
                                            // price={Percent && ( Percent_Symbol_Before && Percent_Symbol_Before + ' ' && '-' ) + Percent + ( Percent_symbol && Percent_symbol || '%') || null}
                                            target={YandexTarget}
                                            attributes={attributes}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            )}
            {shops && shops.length > 0 && (
                <div className={cn("results__category", (sales && sales.length > 0 || topAlpha && topAlpha.length > 0) && "second")}>
                    <div className='category _default'>
                        <div className='category__header'>
                            <div className='category__title'>{categoriesById && categoriesById[id] && categoriesById[id].Title}</div>
                        </div>
                        <div className='category__list _vertical'>
                            {shops.map((item, index) => {

                                const { id, attributes } = item;

                                const { Title, Preview, Logo, Sale, Sale_card, Preview_list, Subtitle, Show_percent_on_preview } = attributes;

                                let Percent = null;

                                let Percent_symbol = null;

                                let Percent_Symbol_Before = '-';
                                
                                if(Sale_card){
                                    if(Sale_card.Percent){
                                        Percent = Sale_card.Percent;
                                    }
                                    if(Sale_card.Percent_symbol){
                                        Percent_symbol = Sale_card.Percent_symbol;
                                    }
                                    if(Sale_card.Percent_Symbol_Before){
                                        Percent_Symbol_Before = Sale_card.Percent_Symbol_Before;
                                    }
                                } else {
                                    if(Sale && Sale.data && Sale.data.attributes && Sale.data.attributes.Sale_card){
                                        if(Sale.data.attributes.Sale_card.Percent){
                                            Percent = Sale.data.attributes.Sale_card.Percent;
                                        }
                                        if(Sale.data.attributes.Sale_card.Percent_symbol){
                                            Percent_symbol = Sale.data.attributes.Sale_card.Percent_symbol;
                                        }
                                    }
                                }
                                
                                return (
                                    <div key={id} className='category__list-item'>
                                        <PostShop
                                            link={'/shop/'+id}
                                            preview={Preview_list && getUrlImage(Preview_list) || Preview && getUrlImage(Preview) || ''}
                                            logo={Logo && getUrlImage(Logo) || ''}
                                            title={Title}
                                            sale={(Show_percent_on_preview || Show_percent_on_preview == null ) && Percent && Percent_Symbol_Before+Percent+( Percent_symbol && Percent_symbol || '%') || null}
                                            category={categoriesById && categoriesById[id] && categoriesById[id].Title}
                                            subTitle={Subtitle}
                                            attributes={attributes}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Results;