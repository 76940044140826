let isCatalog = true;
let configPath = "config.json";
if (
  window.location.pathname === "/app-shop" ||
  window.location.pathname === "/app_shop_ao" ||
  window.location.pathname === "/app_shop_am"
) {
  isCatalog = true;
}
if (window.location.hostname === "podeli.ru") {
  configPath = "https://catalog-cdn.podeli.ru/front/config.json";
}

const CATALOG_TYPES = {
  CATALOG: "catalog",
  APP: "app",
  AM: "am",
  AO: "ao",
};
let catalogType = CATALOG_TYPES.APP;

if (
  window.location.pathname === "/catalog" ||
  window.location.pathname === "/" ||
  window.location.pathname === "/test_catalog"
) {
  isCatalog = true;
  catalogType = CATALOG_TYPES.CATALOG;
} else if (
  window.location.pathname === "/app_shop_am" ||
  window.location.pathname === "/test_app_shop_am"
) {
  isCatalog = false;
  catalogType = CATALOG_TYPES.AM;
} else if (
  window.location.pathname === "/app_shop_ao" ||
  window.location.pathname === "/test_app_shop_ao"
) {
  isCatalog = false;
  catalogType = CATALOG_TYPES.AO;
}
const prepareUtmLink = (link, hide_utm) => {
  let symbol = link.indexOf("?") === -1 ? "?" : "&";
  let utm =
    symbol +
    "utm_source=podeli.ru&utm_medium=banner&utm_campaign=" +
    (isCatalog ? "podeli_web" : "podeli_app");
  return link + (!hide_utm ? utm : "");
};
export { isCatalog, configPath, catalogType, CATALOG_TYPES, prepareUtmLink };

export const getUrlImage = (props) => {
  return (props.data && props.data.attributes.url) || "";
};

export const targetMetrick = (target) => {
  if (window.ym) {
    window.ym(93798687, "reachGoal", target);
  }
};

export const shuffle = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

export const shuffleArrayExceptFirstThree = (array) => {
  // Проверка, что массив имеет более трех элементов
  if (array.length <= 6) {
    return array; // Нечего перемешивать
  }

  // Разделение массива на три части: первые три элемента и оставшаяся часть
  const firstThree = array.slice(0, 6);
  const rest = array.slice(6);

  // Функция для перемешивания массива с использованием алгоритма Фишера-Йетса
  function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
    return array;
  }

  // Перемешиваем оставшуюся часть массива
  const shuffledRest = shuffle(rest);

  // Соединяем первые три элемента и перемешанную оставшуюся часть
  return firstThree.concat(shuffledRest);
};
