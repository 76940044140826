import React, { useEffect, useRef } from "react";

import { Link } from "react-router-dom";
import { targetMetrick } from "../../constants/config";

import "./style.css";

const PostShop = (props) => {
  const {
    link,
    preview,
    logo,
    sale,
    title,
    category,
    subTitle,
    dopClass,
    attributes,
  } = props;

  return (
    <Link
      to={(attributes.Slug && "/shop/" + attributes.Slug) || link}
      className={`post _shop carousel-control ${(dopClass && dopClass) || ""}`}
      onClick={(e) => {
        targetMetrick("Партнер." + title + ".Переход");
      }}
    >
      <span className="post__preview">
        {preview && (
          <img src={preview} loading="lazy" className="post__preview_img" />
        )}
        {logo && (
          <span className="post__logo">
            <img src={logo} />
          </span>
        )}
        {sale && <span className="post__sale">{sale}</span>}
      </span>
      <span className="post__title">{title}</span>
      {subTitle && <span className="post__category">{subTitle}</span>}
    </Link>
  );
};

export default PostShop;
